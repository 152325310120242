<template>
  <div style="text-align: left">
    <el-button
      type="primary"
      class="z-depth-0"
      @click="showeditActivityDialog"
    >Edit An Activity</el-button>

    <article>
      <section>
        <div class="activity_dialogs">
          <el-dialog
            :title="`Edit An Activity`"
            :visible.sync="editActivityDialog"
            :destroy-on-close="true"
            :before-close="handleClose"
            style="text-align: left"
          >
            <div v-loading="isUploadingFile">
              <el-form
                :model="activityForm"
                :rules="activityRules"
                ref="activityForm"
                class="demo-activityForm2"
              >
                <div class="row">
                  <div class="col-md-12">
                    <label>Activity Title</label>
                    <el-form-item prop="title">
                      <el-input v-model="activityForm.title"></el-input>
                    </el-form-item>
                  </div>
                </div>

                <hr />
                <el-form-item class="text-center">
                  <el-button
                    type="primary"
                    @click="submitActivityForm('activityForm')"
                  >Edit Activity</el-button>
                </el-form-item>
              </el-form>

              <div class="text-center">
                <div class="text-center">
                  <small>© 2022 Ihamba Adventures All rights reserved.</small>
                </div>
              </div>
            </div>
          </el-dialog>
        </div>
      </section>
    </article>
  </div>
</template>


<script>
export default {
  data() {
    return {
      editActivityDialog: false,
      isUploadingFile: false,
      value: "",
      loading: false,
      loadingError: false,
      activityForm: {
        title: "",
        package_id: `${this.packageId}`,
      },

      activityRules: {
        title: [
          {
            required: true,
            message: "Title is required",
            trigger: "blur",
          },
        ],
      },
    };
  },

  props: {
    packageId: {
      required: true,
      type: String,
    },

    isEditActivityVisible: {
      type: Boolean,
      required: true,
    },

    activityData: {
      type: Object,
      required: true,
    },
  },

  watch: {
    isEditActivityVisible() {
      if (this.isEditActivityVisible == true) {
        this.editActivityDialog = true;
        this.activityForm.title = this.activityData.title;
        this.activityForm.package_id = this.packageId;
      } else {
        this.editActivityDialog = false;
      }
    },
  },

  methods: {
    handleClose(done) {
      done();
      this.$emit("closeEditActivityDialog");
    },

    async submitActivityForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            this.isUploadingFile = true;
            let request = await this.$http.post(
              `api/safari/package/activity/edit/${this.activityData.id}`,
              {
                title: this.activityForm.title,
                package_id: `${this.packageId}`,
              }
            );
            if (
              request.data.success &&
              request.data.message == "ACTIVITY_EDITED_SUCCESSFULLY"
            ) {
              this.$emit("refreshActivities");
              this.$emit("closeEditActivityDialog");
              this.$notify({
                title: "Success",
                message: "Activity updated Successfully",
                type: "success",
              });
            } else throw "UNEXPECTED_RESPONSE";
          } catch (error) {
            if (error.message == "NetworkError") {
              this.isUploadingFile = false;
              return this.$notify({
                title: "Connection failed",
                message: "A network error occurred please try again",
                type: "warning",
              });
            }
            this.isUploadingFile = false;
            this.$notify({
              title: "Upload Failed",
              message: "Unable to Update Activity now, Please try again",
              type: "error",
            });
          } finally {
            this.isUploadingFile = false;
          }
        } else {
          return false;
        }
      });
    },

    showeditActivityDialog() {
      this.editActivityDialog = true;
    },
  },
};
</script>

<style scoped>
input[type="text"],
select,
textarea {
  width: 100%;
  padding: 10px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  background-color: #ffffff;
  font-size: 0.9em;
}

.search_add_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search_by_input {
  width: 400px !important;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .search_add_section {
    display: block;
  }
  .addbtn {
    width: 100%;
    margin-top: 10px;
  }
  .search_by_input {
    width: 100% !important;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .search_add_section {
    display: block;
  }
  .addbtn {
    width: 100%;
    margin-top: 10px;
  }
  .search_by_input {
    width: 100% !important;
  }
}
</style>

<style>
.activity_dialogs .el-dialog {
  width: 40%;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
  .activity_dialogs .el-dialog {
    width: 60%;
  }
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .activity_dialogs .el-dialog {
    width: 70%;
  }
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .activity_dialogs .el-dialog {
    width: 90%;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .activity_dialogs .el-dialog {
    width: 90%;
  }
}
</style>
